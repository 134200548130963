<template>
  <MatchGameLayout
    class="order-cards"
    v-model="localCards"
    :isDraggable="isDisabled"
    :checkMove="handleMove"
    @handleDragEnd="handleDragEnd"
  >
    <template #top-items>
      <MatchGameCard
        class="order-cards__option"
        v-for="card in topCards"
        :key="`top-image-${card.index}`"
        :imageURL="card.image"
      />
    </template>

    <template #bottom-items>
      <MatchGameCard
        v-for="card in localCards"
        class="order-cards__option order-cards__option--draggable"
        :disabled="isDisabled"
        :key="card.index"
        :imageURL="!card.hidden ? card.image : DEFAULT_IMAGE"
        :icon="card.symbol"
        @click.native="!card.hidden ? zoomCard(card) : ''"
      />
    </template>

    <template #submit>
      <RtbButton
        class="font-bold capitalize text-lg px-3"
        @click="onSubmit()"
        :disabled="!showSubmit"
      >
        {{ submitText }}
      </RtbButton>
    </template>

    <template #pointer>
      <DragPointer
        rootClassname=".order-cards"
        elementClassname=".order-cards__option--draggable"
      />
    </template>
  </MatchGameLayout>
</template>

<script>
import { mapGetters } from "vuex"
import draggable from "vuedraggable"
import { RtbButton } from "@/components/ui"
import User from "@shared/User"
import DragPointer from "./Parts/DragPointer.vue"
import MatchGameLayout from "./Parts/MatchGameLayout.vue"
const MatchGameCard = () =>
  import("@/components/GroupTeams/Common/Player/MatchGameCard.vue")
import useMatchGame from "./useMatchGame"

const SYMBOLS = [
  "change_history", // triangle
  "circle",
  "favorite",
  "star",
  "add",
  "clear",
  "check_circle",
  "mail",
  "school",
  "face",
  "thumb_up",
  "paid"
]

const DEFAULT_IMAGE = require("@/assets/order-the-card-default.png")

export default {
  name: "OrderTheCards",
  components: {
    MatchGameLayout,
    MatchGameCard,
    DragPointer,
    RtbButton,
    draggable
  },
  setup() {
    const { isDisabled, showSubmit, submitText } = useMatchGame()
    return { isDisabled, showSubmit, submitText }
  },
  data() {
    return {
      localCards: [],
      target: null,
      DEFAULT_IMAGE
    }
  },
  created() {
    this.buffer = {
      movingIndex: 0,
      futureIndex: 0
    }
  },
  watch: {
    cards: {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.isPresenter && this.localCards.length) return
        this.localCards = val
      }
    }
  },
  computed: {
    ...mapGetters(["isScribe"]),
    ...mapGetters("auth", ["isAudit", "isSpectator", "isHost", "user"]),
    ...mapGetters({
      mission: "getCurrentMission",
      teams: "chats"
    }),
    isPresenter() {
      return User.isPresenter(this.user)
    },
    topCards() {
      if (Array.isArray(this.mission?.orderCardsTop)) {
        return this.mission.orderCardsTop.map((image, index) => ({
          image,
          index,
          value: null
        }))
      }
      return []
    },
    originalCards() {
      return (this.mission?.orderCardsBottom ?? []).map((image, index) => ({
        image,
        index
      }))
    },
    cards() {
      return this.team?.otherTeamFacts?.map(({ image, index, usersCanSee }) => {
        return {
          image,
          index,
          symbol: SYMBOLS[index],
          usersCanSee: usersCanSee ?? null,
          hidden: this.isHost ? false : !usersCanSee?.[this.user?.id]
        }
      })
    },
    team() {
      return this.$store.getters["group/canViewerSelectGlobalTeam"]
        ? this.teams[this.$store.getters["group/globalTeamID"]]
        : this.teams[this.user?.teamID]
    }
  },
  methods: {
    onSubmit() {
      const correct = this.originalCards.filter(
        (obj, idx) => this.cards[idx].index === obj.index
      )

      const numOfCards = this.originalCards.length
      const num = parseInt(this.mission?.points) || 0
      const points = parseInt(num / numOfCards)
      var score = correct.length * points
      const answer = []
      if (this.originalCards.length == correct.length) score = num

      this.originalCards.forEach((card, indx) => {
        answer.push({
          index: this.cards?.[indx].index,
          image: this.cards?.[indx].image,
          result: this.cards?.[indx].index === indx
        })
      })

      this.$store.dispatch("addFactMatchPlay", { score, answer })
    },
    zoomCard(card) {
      this.$bus.$emit("zoomMissionAssetPhoto", {
        visible: true,
        photo: card.image,
        title: ""
      })
    },
    handleDragEnd() {
      const { movingIndex, futureIndex } = this.buffer
      const futureItem = this.localCards[futureIndex]
      const movingItem = this.localCards[movingIndex]
      if (futureItem !== undefined && movingItem !== undefined) {
        const cards = this.localCards.slice(0)
        cards[futureIndex] = movingItem
        cards[movingIndex] = futureItem
        this.localCards = cards
        const obj = {
          otherTeamFacts: {
            ...this.localCards,
            symbole: null,
            hidden: null
          },
          id: this.team.id
        }
        this.$store.dispatch("updateTeam", obj)
      }
    },
    handleMove(e) {
      const { index: movingIndex, futureIndex } = e.draggedContext
      this.buffer = { movingIndex, futureIndex }
      return false
    }
  }
}
</script>

<style lang="scss">
.order-cards {
  color: #fff;

  --order-cards-col-width: 18vh;

  &__option {
    width: var(--order-cards-col-width);
    max-width: var(--order-cards-col-width);

    .mobile-layout & {
      --order-cards-col-width: 120px;
    }

    &--draggable {
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
    }
  }
}
</style>
