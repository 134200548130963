var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MatchGameLayout", {
    staticClass: "order-cards",
    attrs: { isDraggable: _vm.isDisabled, checkMove: _vm.handleMove },
    on: { handleDragEnd: _vm.handleDragEnd },
    scopedSlots: _vm._u([
      {
        key: "top-items",
        fn: function () {
          return _vm._l(_vm.topCards, function (card) {
            return _c("MatchGameCard", {
              key: "top-image-" + card.index,
              staticClass: "order-cards__option",
              attrs: { imageURL: card.image },
            })
          })
        },
        proxy: true,
      },
      {
        key: "bottom-items",
        fn: function () {
          return _vm._l(_vm.localCards, function (card) {
            return _c("MatchGameCard", {
              key: card.index,
              staticClass: "order-cards__option order-cards__option--draggable",
              attrs: {
                disabled: _vm.isDisabled,
                imageURL: !card.hidden ? card.image : _vm.DEFAULT_IMAGE,
                icon: card.symbol,
              },
              nativeOn: {
                click: function ($event) {
                  !card.hidden ? _vm.zoomCard(card) : ""
                },
              },
            })
          })
        },
        proxy: true,
      },
      {
        key: "submit",
        fn: function () {
          return [
            _c(
              "RtbButton",
              {
                staticClass: "font-bold capitalize text-lg px-3",
                attrs: { disabled: !_vm.showSubmit },
                on: {
                  click: function ($event) {
                    return _vm.onSubmit()
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.submitText) + " ")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "pointer",
        fn: function () {
          return [
            _c("DragPointer", {
              attrs: {
                rootClassname: ".order-cards",
                elementClassname: ".order-cards__option--draggable",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.localCards,
      callback: function ($$v) {
        _vm.localCards = $$v
      },
      expression: "localCards",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }